exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appel-a-projets-js": () => import("./../../../src/pages/appel-a-projets.js" /* webpackChunkName: "component---src-pages-appel-a-projets-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-coups-de-coeur-js": () => import("./../../../src/pages/coups-de-coeur.js" /* webpackChunkName: "component---src-pages-coups-de-coeur-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-editorial-js": () => import("./../../../src/pages/editorial.js" /* webpackChunkName: "component---src-pages-editorial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-credits-js": () => import("./../../../src/pages/legal/credits.js" /* webpackChunkName: "component---src-pages-legal-credits-js" */),
  "component---src-pages-legal-mentions-legales-js": () => import("./../../../src/pages/legal/mentions-legales.js" /* webpackChunkName: "component---src-pages-legal-mentions-legales-js" */),
  "component---src-pages-multimedia-js": () => import("./../../../src/pages/multimedia.js" /* webpackChunkName: "component---src-pages-multimedia-js" */),
  "component---src-pages-scolaires-js": () => import("./../../../src/pages/scolaires.js" /* webpackChunkName: "component---src-pages-scolaires-js" */),
  "component---src-templates-events-index-js": () => import("./../../../src/templates/events-index.js" /* webpackChunkName: "component---src-templates-events-index-js" */),
  "component---src-templates-news-show-js": () => import("./../../../src/templates/news-show.js" /* webpackChunkName: "component---src-templates-news-show-js" */),
  "component---src-templates-village-show-js": () => import("./../../../src/templates/village-show.js" /* webpackChunkName: "component---src-templates-village-show-js" */)
}

